// 位置枚举
const PositionEnum = {
  HOME_PAGE: 0, // 首页
  START_UP_BUSINESS: 2, // 无忧创业
  MY_EQUITY: 3, // 我的权益
};

// 位置文案枚举
const PositionTextEnum = {
  0: '首页',
  2: '无忧创业',
  3: '我的权益',
};

// 位置选项
const positionOptions = [
  {
    value: PositionEnum.HOME_PAGE,
    label: PositionTextEnum[PositionEnum.HOME_PAGE],
  },
  {
    value: PositionEnum.START_UP_BUSINESS,
    label: PositionTextEnum[PositionEnum.START_UP_BUSINESS],
  },
  {
    value: PositionEnum.MY_EQUITY,
    label: PositionTextEnum[PositionEnum.MY_EQUITY],
  },
];

// 状态枚举
const StatusEnum = {
  ENABLE: 'enabled', // 启用
  DISABLED: 'disabled', // 禁用
};

// 状态文案枚举
const StatusTextEnum = {
  enabled: '启用',
  disabled: '禁用',
};

// 状态选项
const statusOptions = [
  {
    value: StatusEnum.ENABLE,
    label: StatusTextEnum[StatusEnum.ENABLE],
  },
  {
    value: StatusEnum.DISABLED,
    label: StatusTextEnum[StatusEnum.DISABLED],
  },
];

// 排序枚举
const SortEnum = {
  UPDATE: 'update', // 更新时间
  PRIORITY: 'priority', // 优先级
};

// 排序文案枚举
const SortTextEnum = {
  update: '更新时间',
  priority: '优先级',
};

// 排序选项
const sortOptions = [
  {
    value: SortEnum.UPDATE,
    label: SortTextEnum[SortEnum.UPDATE],
  },
  {
    value: SortEnum.PRIORITY,
    label: SortTextEnum[SortEnum.PRIORITY],
  },
];

// 透出端口枚举
const ShowPortEnum = {
  APP: 1, // APP
  MINI_PROGRAM: 2, // 小程序
  ALL: 3
};

// 透出端口文案枚举
const ShowPortTextEnum = {
  1: 'APP',
  2: '小程序',
  3: '同时透出'
};

// 透出端口选项
const showPortOptions = [
  {
    value: ShowPortEnum.APP,
    label: ShowPortTextEnum[ShowPortEnum.APP],
  },
  {
    value: ShowPortEnum.MINI_PROGRAM,
    label: ShowPortTextEnum[ShowPortEnum.MINI_PROGRAM],
  },
  {
    value: ShowPortEnum.ALL,
    label: ShowPortTextEnum[ShowPortEnum.ALL],
  },
];

// 类型枚举
const TypeEnum = {
  LINK: 'link', // 链接
  CUSTOM: 'custom', // 自定义
};

// 类型文案枚举
const TypeTextEnum = {
  link: '链接',
  custom: '自定义',
};

// 类型选项
const typeOptions = [
  {
    value: TypeEnum.LINK,
    label: TypeTextEnum[TypeEnum.LINK],
  },
  {
    value: TypeEnum.CUSTOM,
    label: TypeTextEnum[TypeEnum.CUSTOM],
  },
];

// 项目级联自定义字段配置
const projectOptionsFieldNames = { label: 'project_name', value: 'id', children: 'child' };

// 返回按钮枚举
const ShowBackButtonEnum = {
  SHOW: 1,
  HIDE: 0,
};

// 返回按钮文案枚举
const ShowBackButtonTextEnum = {
  1: '显示',
  0: '不显示',
};

// 返回按钮选项
const showBackButtonOptions = [
  {
    value: ShowBackButtonEnum.SHOW,
    label: ShowBackButtonTextEnum[ShowBackButtonEnum.SHOW],
  },
  {
    value: ShowBackButtonEnum.HIDE,
    label: ShowBackButtonTextEnum[ShowBackButtonEnum.HIDE],
  },
];

// 是否支持分享枚举
const SupportShareEnum = {
  YES: 1,
  NO: 0,
};

// 是否支持分享文案枚举
const SupportShareTextEnum = {
  1: '是',
  0: '否',
};

// 是否支持分享选项
const supportShareOptions = [
  {
    value: SupportShareEnum.NO,
    label: SupportShareTextEnum[SupportShareEnum.NO],
  },
  {
    value: SupportShareEnum.YES,
    label: SupportShareTextEnum[SupportShareEnum.YES],
  },
];

const table = {
  dataInfo: [],
  columns: [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '透出端口',
      dataIndex: 'penetrate',
      key: 'penetrate',
      customRender: (text) => ShowPortTextEnum[text],
    },
    {
      title: '可见项目',
      dataIndex: 'project_name',
      key: 'project_name',
    },
    {
      title: '位置',
      dataIndex: 'place_to_module_text',
      key: 'place_to_module_text',
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      customRender: (text) => TypeTextEnum[text],
    },
    {
      title: '优先级',
      dataIndex: 'priority',
      key: 'priority',
    },
    {
      title: '更新时间',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: '有效日期',
      dataIndex: 'valid_time',
      key: 'valid_time',
    },
    {
      title: '状态',
      dataIndex: 'state',
      key: 'state',
      customRender: (text) => StatusTextEnum[text],
    },
    {
      title: '操作',
      key: 'action',
      width: '200px',
      scopedSlots: { customRender: 'action' },
      fixed : 'right'
    },
  ],
  //分页数据
  pagination: {
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  },
};

// 搜索表单
const searchForm = {
  title: '', // 标题
  show_location: '', // 位置
  status: '', // 状态
  order_by: 'update', // 排序方式
  time: [], // 更新日期
  show_port: '', // 透出端口
  type: '', // 类型
  visible_project: [], // 可见项目
  time1: [], // 有效日期
}


export default {
  PositionEnum,
  PositionTextEnum,
  positionOptions,
  StatusEnum,
  StatusTextEnum,
  statusOptions,
  SortEnum,
  SortTextEnum,
  sortOptions,
  ShowPortEnum,
  ShowPortTextEnum,
  showPortOptions,
  TypeEnum,
  TypeTextEnum,
  typeOptions,
  table,
  projectOptionsFieldNames,
  ShowBackButtonEnum,
  ShowBackButtonTextEnum,
  showBackButtonOptions,
  SupportShareEnum,
  SupportShareTextEnum,
  supportShareOptions,
  searchForm
};
